/* External dependencies */
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

/* Local dependencies */
import Layout from '../../components/layout';
import WarehousesComponent from '../../components/warehouses';
import formatMessage from '../../intl/formatMessage';

export default function Warehouses() {
  const title = formatMessage('warehouses');
  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = useStaticQuery(query);

  return (
    <Layout>
      <Helmet>
        <title>{titleTemplate.replace('%s', title)}</title>
      </Helmet>
      <WarehousesComponent />
    </Layout>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
