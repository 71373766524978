// External dependencies
import { Row, Col, Div, Text } from 'atomize';
import { FormattedMessage, navigate } from 'gatsby-plugin-react-intl';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local dependencies
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import PrimaryButton from '../buttons/PrimaryButton';
import { listWarehousesForUnloading } from '../consignees/redux/warehouse-for-unload/actions';
import { WarehousesForUnloadingState } from '../consignees/redux/warehouse-for-unload/reducer';
import Search from '../search/Search';
import Tabs from '../tabs/Tabs';
import { adminTabs } from './warehouses-tabs';

export function ControlBar() {
  const { searchString, filter, loading } = useSelector(
    (state: RootState): WarehousesForUnloadingState =>
      state.warehousesForUnloading,
  );

  const dispatch = useDispatch();

  const findWarehouse = formatMessage('findWarehouse');

  function onSearch(e) {
    dispatch(listWarehousesForUnloading(e.target.value, 1, filter));
  }

  function resetSearchField() {
    onSearch({
      target: {
        value: '',
      },
    });
  }

  function handleCreateWarehouse() {
    navigate('/warehouses/create');
  }

  function onFilterChange(e) {
    const { value } = e.target;

    dispatch(listWarehousesForUnloading(searchString, 1, { status: value }));
  }

  return (
    <Row m={{ y: '.5rem', x: '-.5rem' }}>
      <Col size={{ xs: '12', lg: '6' }}>
        {
          <Tabs
            inputName=""
            items={adminTabs}
            onClick={onFilterChange}
            value={filter?.status}
          />
        }
      </Col>
      <Col
        size={{ xs: '12', lg: '6' }}
        d={{ xs: 'block', md: 'flex' }}
        justify={{ xs: 'flex-start', lg: 'flex-end' }}
      >
        <Div
          d={{ xs: 'block', md: 'flex' }}
          flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
        >
          {/* <Div
            m={{ r: { xs: 0, sm: '1.5rem' } }}
            style={{ marginBottom: '1rem' }}
          >
            <Search
              onChange={onSearch}
              onClear={resetSearchField}
              placeholder={findWarehouse}
              value={searchString}
            />
          </Div> */}
          <Div
            pos="relative"
            style={{ marginBottom: '1rem' }}
            className="wrapper-action"
          >
            <PrimaryButton
              onClick={handleCreateWarehouse}
              icon="Plus"
              disabled={loading}
            >
              <Text textSize="little" style={{ whiteSpace: 'nowrap' }}>
                {formatMessage('createWarehouseBtn')}
              </Text>
            </PrimaryButton>
          </Div>
        </Div>
      </Col>
    </Row>
  );
}

export function getDefaultFields() {
  return [
    {
      field: 'name',
      title: <FormattedMessage id="organization" />,
    },
  ];
}
