// Local dependencies
import { WarehouseStatus } from './types';

export const adminTabs = [
  {
    label: 'active',
    value: WarehouseStatus.ENABLED,
  },
  {
    label: 'notActive',
    value: WarehouseStatus.DISABLED,
  },
];
